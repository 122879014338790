import { FC } from 'react';

import { getIllustration, wrap } from '@sitecore/common';
import { TopTasksRendering } from '@sitecore/types/manual/TopTasks';
import { Box, Hidden, Stack, Text, TileBar } from '@sparky';
import { ChevronRightIcon } from '@sparky/icons';

const TopTasks: FC<TopTasksRendering> = ({ fields }) => {
  if (!fields?.items || fields.items.length === 0) {
    return null;
  }

  return (
    <Box paddingTop="8">
      <TileBar direction={{ initial: 'column', md: 'row' }}>
        {fields.items.map((item, i) => {
          const Illustration = getIllustration(item.fields.illustration.value);
          return (
            <TileBar.Item
              linkValue={item.fields.link.value}
              linkType={item.fields.link.value.linktype}
              key={i}
              hoverContent={
                <Text size="BodyXS" align="center">
                  {wrap(item.fields.subTitle)}
                </Text>
              }>
              <Stack direction="row" alignY="center" alignX={{ initial: 'justify', md: 'center' }}>
                <Stack
                  direction={{ initial: 'row', md: 'column' }}
                  gap={{ initial: '6', md: '3' }}
                  alignY="center"
                  alignX={{ initial: 'start', md: 'center' }}>
                  <Illustration size={{ initial: 'small', md: 'medium' }} color="iconSecondary" />
                  <Text align={{ initial: 'left', md: 'center' }} size="BodyS" weight="bold">
                    {wrap(item.fields.title)}
                  </Text>
                </Stack>
                <Hidden above="md">
                  <div>
                    <ChevronRightIcon />
                  </div>
                </Hidden>
              </Stack>
            </TileBar.Item>
          );
        })}
      </TileBar>
    </Box>
  );
};

export default TopTasks;
